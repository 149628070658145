<template>
    <v-btn :loading="loading" elevation="0" :large="isLarge" :disabled="loading" :color="color"  class="white--text" rounded
        @click="store">
        {{label}}
        <v-icon v-if="icon" right dark>
            {{icon}}
        </v-icon>
    </v-btn>
</template>

<script>
  export default {
       data () {
      return {
        loader: null,
        loading: false,
      }
    },
    methods:{
        store(){
            this.$emit('store');
        },
        load(active){
            this.loading = active
        }
    },
    props: ['color','icon', 'label','isLarge'],
  }
</script>